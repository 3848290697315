import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useSiteState } from '~context/siteContext'

const CursorPanel = ({ className, cursorMessage, color, onClick }) => {

	const [siteState, setSiteState] = useSiteState()

	return (
		<>
			<Wrap 
				className={className} 
				onMouseEnter={() => setSiteState(prevState => ({
					...prevState,
					customCursor: true,
					cursorColor: color,
					cursorMessage: cursorMessage
				}))}
				onMouseLeave={() => setSiteState(prevState => ({
					...prevState,
					customCursor: false,
					cursorColor: undefined,
					cursorMessage: undefined
				}))}
				onClick={onClick}
			/>
		</>
	)
}

const Wrap = styled.button`
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	cursor: none;
	${mobile}{
		display: none;
	}
`

CursorPanel.propTypes = {
	className: PropTypes.string,
	cursorMessage: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func,
}

export default CursorPanel
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'

const DoubleMedia = ({ className, content }) => {
	return (
		<Wrap className={className}>
			<Media>
				{content.leftMediaType === 'image' ? 
					<Image image={content.leftImage} />
					:
					<Video src={content?.leftVideo?.video?.asset?.url} playsInline={true} autoPlay={true} muted loop/>
				}
			</Media>
			<Media>
				{content.rightMediaType === 'image' ? 
					<Image image={content.rightImage} />
					:
					<Video src={content?.rightVideo?.video?.asset?.url} playsInline={true} autoPlay={true} muted loop/>
				}
			</Media>
		</Wrap>
	)
}

const Wrap = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 25px;
	${mobile}{
		grid-column-gap: 20px;
	}
`
const Video = styled.video`
	width: 100%;
`
const Media = styled.div`
	line-height: 0px;
`

DoubleMedia.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default DoubleMedia
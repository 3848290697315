import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import RichText from '~components/RichText'

const TextModule = ({ className, content }) => {
	return (
		<Wrap className={className}>
			<Col>
				<RichText content={content.leftColumn}/>
			</Col>
			<Col>
				<RichText content={content.rightColumn}/>
			</Col>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	margin: calc(var(--m) - 25px) -25px;
	> div{
		grid-row-gap: 1.1em;
	}
	${mobile}{
		margin: calc(var(--m-m) - 20px) -20px;
	}
`
const Col = styled.div`
	grid-column: span 8;
	${mobile}{
		grid-column: span 16;
	}
`

TextModule.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default TextModule